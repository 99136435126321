import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SubscriptionService } from '../services/subscription.service';
import { RestaurantService } from '../services/restaurant.service';



@Injectable({
  providedIn: 'root'
})
export class PaymentDataGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private subscription: SubscriptionService,
    private restaurant: RestaurantService,
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>|boolean {
      return new Promise<boolean>((resolve, reject) => {
        resolve(true)
        /*
        - Consultar si hi ha dades de pagament i ja han passat 14 dies des del registre.
        - Consultar si admin ha donat de baixa aquest restaurant
        */
        /*this.restaurant.getcreationdate(this.auth.getIdRestaurant()).subscribe(result => {
          if(result) {
            console.log(result)
            var days
            var isOldrestaurant = result.restaurant.Oldrestaurant
            if(result.restaurant.created_at){
              days = this.getDaysBetweenDates(result.restaurant.created_at)
            }
            else {
              days = 1
            }

            this.subscription.getRestaurantSubscriptionById(this.auth.getIdRestaurant()).subscribe(result => {
                console.log("RESTAURANT", result)
                console.log("DAYS", days)
                if(days > 14) {
                  //subscription_end
                  if(this.isBeforeToday(result.subscription_end) && result.subscription_end > 0) {
                    sessionStorage.setItem('PaymentDataAlert', '0')
                    resolve(true)
                  }
                  else {
                    console.log("PATH",window.location.pathname )
                    if(window.location.pathname == "/preview/"+this.auth.getIdRestaurant()){
                      sessionStorage.setItem('PaymentDataAlert', '0')
                      this.router.navigate(['login']);
                      //window.open("https://refoodlution.com", "_blank");
                      resolve(false)
                    }
                    else {
                      //Protección per a que només afecti als restaurants nous
                      if(isOldrestaurant){ //ELIMINAR EN UN FUTURO
                        sessionStorage.setItem('PaymentDataAlert', '1')
                        sessionStorage.setItem('PaymentDataDays', days+'')
                        resolve(true)
                      }
                      else {
                        sessionStorage.setItem('PaymentDataAlert', '1')
                        sessionStorage.setItem('PaymentDataDays', days+'')
                        this.router.navigate(['configuration']);
                        resolve(false)
                      }
                      
                    }
                  }
                }
                else {
                  if(this.isBeforeToday(result.subscription_end) && result.subscription_end > 0) {
                    sessionStorage.setItem('PaymentDataAlert', '0')
                    resolve(true)
                  }
                  else {
                    sessionStorage.setItem('PaymentDataAlert', '1')
                    resolve(true)
                  }
                }
            })
          }
        })
        */

        
      }) 

      

    }

  getDaysBetweenDates(date) {
    var createdDate = date
    var created = new Date(createdDate * 1000)
    var today = new Date()

    return (today.getTime() - created.getTime()) / (1000 * 60 * 60 * 24);
  }

  isBeforeToday(d) {
    var date = new Date(d)
    var today = new Date()

    return date < today
  }
    
}
